/* screen sizes for responsive design */

$break-sm: 480px
$break-md: 768px
$break-lg: 992px
$break-xl: 1200px
$break-xxl: 1440px
/**/

:root
    --myte-color-primary: #7500c0
    --myte-color-primary-rgb: 117,0,192
    --myte-color-primary-contrast: #ffffff
    --myte-color-primary-contrast-rgb: 255,255,255
    --myte-color-primary-shade: #6700a9
    --myte-color-primary-tint: #831ac6
    --myte-color-primary-linear: linear-gradient(to right, #7500c0, #5c0096)
    --myte-color-primary-highlight: #f5f3ff

    --myte-color-secondary: #ad81fd
    --myte-color-secondary-rgb: 173,129,253
    --myte-color-secondary-contrast: #000000
    --myte-color-secondary-contrast-rgb: 0,0,0
    --myte-color-secondary-shade: #9872df
    --myte-color-secondary-tint: #b58efd

    --myte-color-tertiary: #121281
    --myte-color-tertiary-rgb: 18,18,129
    --myte-color-tertiary-contrast: #ffffff
    --myte-color-tertiary-contrast-rgb: 255,255,255
    --myte-color-tertiary-shade: #101072
    --myte-color-tertiary-tint: #2f61b1

    --myte-color-success: #2b9773
    --myte-color-success-rgb: 43,151,115
    --myte-color-success-contrast: #ffffff
    --myte-color-success-contrast-rgb: 255,255,255
    --myte-color-success-shade: #268565
    --myte-color-success-tint: #40a181

    --myte-color-warning: #fda70a
    --myte-color-warning-rgb: 253,167,10
    --myte-color-warning-contrast: #ffffff
    --myte-color-warning-contrast-rgb: 255,255,255
    --myte-color-warning-shade: #df9309
    --myte-color-warning-tint: #fdb023

    --myte-color-danger: #c9252d
    --myte-color-danger-rgb: 201,37,45
    --myte-color-danger-contrast: #ffffff
    --myte-color-danger-contrast-rgb: 255,255,255
    --myte-color-danger-shade: #b12128
    --myte-color-danger-tint: #ce3b42

    --myte-color-lightest: #ffffff
    --myte-color-lightest-rgb: 255,255,255
    --myte-color-lightest-contrast: #000000
    --myte-color-lightest-contrast-rgb: 0,0,0
    --myte-color-lightest-shade: #e0e0e0
    --myte-color-lightest-tint: #ffffff

    --myte-color-light: #f0f1f5
    --myte-color-light-rgb: 244,244,244
    --myte-color-light-contrast: #000000
    --myte-color-light-contrast-rgb: 0,0,0
    --myte-color-light-shade: #d7d8da
    --myte-color-light-shade-rgb: 215,216,218
    --myte-color-light-tint: #f5f6f9
    --myte-color-light-tint-rgb: 245,246,249

    --myte-color-medium: #989aa2
    --myte-color-medium-rgb: 152,154,162
    --myte-color-medium-contrast: #ffffff
    --myte-color-medium-contrast-rgb: 255,255,255
    --myte-color-medium-shade: #86888f
    --myte-color-medium-shade-rgb: 134,136,143
    --myte-color-medium-tint: #a2a4ab
    --myte-color-medium-tint-rgb: 162,164,171

    --myte-color-dark: #1f2428
    --myte-color-dark-rgb: 31,36,40
    --myte-color-dark-contrast: #ffffff
    --myte-color-dark-contrast-rgb: 255,255,255
    --myte-color-dark-shade: #1b2023
    --myte-color-dark-shade-rgb: 27,32,35
    --myte-color-dark-tint: #353a3e
    --myte-color-dark-tint-rgb: 53,58,62

    --myte-color-darkest: #000000
    --myte-color-darkest-rgb: 0,0,0
    --myte-color-darkest-contrast: #ffffff
    --myte-color-darkest-contrast-rgb: 255,255,255
    --myte-color-darkest-shade: #000000
    --myte-color-darkest-tint: #1a1a1a