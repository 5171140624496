/* mixins for responsive design */

@import './variables.sass'

=screen-size-sm
  @media (min-width: #{$break-sm})
    @content
=screen-size-md
  @media (min-width: #{$break-md})
    @content
=screen-size-lg
  @media (min-width: #{$break-lg})
    @content
=screen-size-xl
  @media (min-width: #{$break-xl})
    @content
=screen-size-xxl
  @media (min-width: #{$break-xxl})
    @content

/**/

=toolbar-size-sm
  @media (max-width: #{$break-sm})
    @content
=toolbar-size-md
  @media (max-width: #{$break-md})
    @content
=toolbar-size-lg
  @media (max-width: #{$break-lg})
    @content
=toolbar-size-xl
  @media (min-width: #{$break-lg})
    @content


=target-ie
  @media all and (forced-colors: none), (forced-colors: active)
    @content
