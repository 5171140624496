@font-face {
    font-family: 'Material Icons';
    src: local('Material Icons'),
        local('MaterialIcons'),
        url('./assets/icons/material-icons/iconfont/material-icons-sharp.woff2') format('woff2'),
        url('./assets/icons/material-icons/iconfont/material-icons-sharp.woff') format('woff'),
        url('./assets/icons/material-icons/iconfont/material-icons-outlined.woff2') format('woff2'),
        url('./assets/icons/material-icons/iconfont/material-icons-outlined.woff') format('woff'),
        url('./assets/icons/material-icons/css/material-icons.min.css') format('css'),
        url('./assets/icons/material-icons/iconfont/filled.css') format('css');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}