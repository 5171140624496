:root{
    --body-background: #fff;
    --body-color: #000;
    --body-disabled: #BAC3BA;
    --body-error: #A72118;
    --body-link: #002fc0;
    --body-link-hover: #0631b4;
    --body-placeholder-color: #5B6061;

    --ag-row-font-color: black;
    --ag-cell-special-background-color: #e8ebed;
    --outline-row: #e8ebed;

    --input-background: #fff;
    --input-border: #928f92;
    --input-focus-border: #5A97D9;
    --input-error-border: #AC261D;
    --input-focus-shadow: #007BFF;
    --input-adjustment: #000000;
    --input-adjustment-background: #E8FDE8;
    --input-adjustment-border: #149D14;

    --input-receipt-background: #585B5D;

    --dropdown-option-hover: #8364CA;
    --dropdown-option-hover-text: #FFFFFF;
    --dropdown-option-invalid: #FDC6C6;

    --tab-menu-color: #4B0273;
    --tab-menu-hover: #EED4FD;
    --tab-menu-bottom-border: #7500C0;

    --controls-color: #626262;
    --controls-shadow: rgba(52,58,64,0.2);
    --controls-bar-color-tools: black;
    --time-tab-color-border-cell: #D9DCDE;

    --terms-of-use-close: #3E4851;
    --terms-of-use-close-hover: #1A242D;
    --replicate-button-color: #3A3D3C;

    --button-disabled-border: #758695;
    --button-disabled-background: #E8EBED;
    --button-disabled-color: #676767;
    --button-border-color: #8611D1;
    --secondary-button-font-color: #7500C0;
    --secondary-button-background-color: #EED4FD;
    --secondary-button-hover-color: #EED4FD;

    body.theme-contrast {
        .form-control:focus {
            box-shadow: 0 0 6px #3194ff;
            border-color: #3194ff !important;
            color: #495057;
            background-color: #fff;
            outline: 0;
        }

        /* toast msgs start */

        .toast-info {
            background-color: #277C95;
        }

        .toast-success {
            background-color: #348634;
        }

        /* toast msgs end */

        /* mat-slide start */

        .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
            opacity: 0.68;
            background-color: transparent;
        }

        .mat-slide-toggle-thumb {
            height: 12px;
            width: 12px;
            background-color: #FFFFFF;
        }

        .mat-slide-toggle-thumb-container {
            top: 1px;
        }

        .mat-slide-toggle-bar {
            width: 29px;
            height: 14px;
        }

        .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
            background-color: #FFFFFF  !important;
        }

        .mat-slide-toggle-bar {
            background-color: #939393  !important;
        }

        .mat-slide-toggle:not(.mat-disabled).cdk-keyboard-focused .mat-slide-toggle-persistent-ripple {
            opacity: 0.64;
        }

        .mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
            opacity: 0.64;
        }

        .mat-slide-toggle.mat-checked .mat-ripple-element {
            opacity: 0.64;
            background-color: transparent;
        }

        .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
            background-color: #2ea82e  !important;
        }

        /* mat-slide end */

        /* ag-grid start */
        myte-time .ag-theme-balham .ag-row {
            border-color: black !important;
            // background-color: var(--ag-row-background-color);
            // color: var(--ag-row-font-color);
        }

        myte-time #myGridCategory {
            border-top: 1px solid #8E8585;
        }

        myte-time .time-grid .ag-row.ag-row-first {
            border-bottom: 2px solid black;
            border-top: 1px solid black;
        }

        myte-time #timeGrid .ag-cell {
            border-bottom-width: 0;
        }

        .timeborders .ag-cell {
            font-weight: 700;}

        myte-time #timeGridFooter .ag-row {
            outline: 2px solid black;
        }
        myte-time #timeGridFooter .ag-cell {
            border-bottom-width: 0 !important;
        }
        myte-time #myGridCategory .ag-row.ag-row-first {
            outline: 2px solid black;  
            border-top: 1px solid black;
        }

        .ag-theme-balham .ag-ltr .ag-cell-no-focus:last-of-type {
            border-right: 1px solid #000000; }

        .ag-theme-balham .ag-row-first {
            border-top: 1px solid black;
        }
        .ag-theme-balham .ag-header-row:last-of-type {
            background-color: white;
            border-top: 4px solid #e8ebed; }
        .ag-theme-balham .ag-row.ag-row-last .ag-cell {
            border-bottom-width: 2px; }

        .ag-theme-balham .ag-cell {
            line-height: 34px;
            border: 1px solid #000000;}

        .ag-theme-balham .ag-ltr .ag-cell-focus {
            border: 2px solid black;
            border-left: 3px solid black;}

        .ag-theme-balham .ag-rtl .ag-cell-focus {
            border: 2px solid black;
            border-left: 3px solid black;}

        .ag-theme-balham .ag-row-selected .ag-cell {
            p.red.bold.underline-hover.cursor-pointer {
                color: #DA9792;
            }
            .ag-icon-checkbox-checked {
                color: white !important;
            }
            .ag-icon-checkbox-unchecked {
                color: white !important;
            }
            myte-checkbox {
                .check-icon::before {
                    color: white !important;
                }
                .form-check {
                    .form-check-input {
                        border: 2px solid #FFFFFF !important;
                        background: transparent;
                    }
                    .form-check-input:checked {
                        background-color: #FFFFFF !important;
                        border: 1px solid #FFFFFF !important;
                    }
                    .form-check-input:checked[type=checkbox] {
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233A7CAD' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                    }
                    .form-check-input:focus {
                        box-shadow: 0 0 0 0.25rem rgb(214 221 247 / 25%)
                    }
                }
            }
            myte-chargecodes-grid-select-checkbox-renderer {
                .form-check-input {
                    border: 2px solid #FFFFFF !important;
                    background: transparent;
                }
                .form-check-input:checked {
                    background-color: #FFFFFF !important;
                    border: 1px solid #FFFFFF !important;
                }
                .form-check-input:checked[type=checkbox] {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233A7CAD' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                }
                .form-check-input:focus {
                    box-shadow: 0 0 0 0.25rem rgb(214 221 247 / 25%)
                }
            }
            myte-toggle {
                .form-check {
                    .form-check-input[type=checkbox] {
                        background-color: #949ba3;
                        border: 1px solid #919aa2;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e");
                    }
                    .form-check-input:checked[type=checkbox] {
                        background-color: #1ef241;
                        border: 1px solid #34eb52;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e") !important;
                    }
                    .form-check-input:focus[type=checkbox] {
                        box-shadow: 0 0 0 0.25rem rgb(114 128 138 / 25%);
                    }
                }
            }
            .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
                background-color: #2ea82e !important;
            }
            .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
                background-color: white !important;
            }
        }

        /* Here all the cells style when the row is selected */
        .ag-theme-balham .ag-row-selected .ag-cell {
            background-color: #3A7CAD;
            color: #FFFFFF;
            .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
                background-color: #099109  !important;
            }

            .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
                background-color: #4BFD4B  !important;;
            }
            .assignment-container {
                &.invalid {
                    background-color: #FDC6C6;
                    color: black !important;
                }
                &.netWork {
                    background-color: #ffff00 !important;
                    color: black !important;
                    height: 36px;
                }
                &.newNetWork {
                    background-color: #FDC6C6 !important;
                    color: black !important;
                }
                &.suspense {
                    background-color: #F0D6FF !important;
                    color: black !important;
                }
                &.staticError {
                    background-color: #FDC6C6 !important;
                    color: black !important;
                }
                &.msacode {
                    background-color: #FDC6C6 !important;
                    color: black !important;
                }
            }
            &.hasAdjustment-cell {
                background-color: #1F9C29 !important;
                color: black !important;
            }

            &.hasJPError-cell {
                background-color: #FDFF83 !important;
                color: black !important;
            }

            &.hasError-cell {
                background-color: #FDC6C6 !important;
                color: black !important;
            }

            &.error-cell {
                background-color: #FDC6C6 !important;
                color: black !important;
            }

            &.suspense-cell {
                background-color: #F0D6FF !important;
                color: black !important;
            }

            &.custom-day {
                background-color: #FFC000;
                color: black !important;
            }
        }

        .ag-theme-balham .ag-row-selected .ag-cell button {
            background-color: #3A7CAD;
            color: #FFFFFF;
            font-weight: 700;
            input {
                color: #FFFFFF !important;
            }
        }

        /* ag-grid end */

        /* time grid start */

        myte-time .ag-theme-balham .ag-cell button {
            font-weight: 700;
        }

        .ag-theme-balham .ag-cell {
            &.invalid {
                background-color: #BD362F;
                color: white !important;
                .red {
                    color: #ffa59e !important;
                }
                span {
                    color: white !important;
                }
                mat-icon {
                    color: white !important;
                }
            }
            &.netWork {
                background-color: #ffff00 !important;
                color: black !important;
            }
            &.newNetWork {
                background-color: #BD362F !important;
                color: white !important;
            }
            &.suspense {
                background-color: #bf59eb !important;
                color: black !important;
            }
            &.staticError {
                background-color: #BD362F !important;
                color: white !important;
            }
            &.msacode {
                background-color: #BD362F !important;
                color: white !important;
            }
            &.solidborder-left {
                border: 3px solid #0000b5 !important;
                border-right: 0px !important;
                border-bottom-width: 2px !important;
            }
            &.solidborder-right {
                border: 3px solid #0000b5 !important;
                border-left: 0px !important;
                border-bottom-width: 2px !important;
            }
        }

        /* Cell Colors */

        .timeborders .color-total {
            background-color: #E8EBED;
            font-weight: 700;
        }

        .timeborders .readonly-cell {
            font-weight: 700 !important;
        }

        .readonly-cell {
            background-color: #f3f5f6;
        }

        .weekend-cell {
            background-color: #f3f5f6;
        }

        .replicate-cell {
            border-left: none !important;
            outline: none !important;
            &.ag-cell-focus {
                border-top: 2px black solid !important;
                border-bottom: 2px black solid !important;
                border-right: 2px black solid !important;
            }
        }

        .charcodecell {
            border-right: none !important;
            &.ag-cell-focus {
                border-top: 1px black solid !important;
                border-bottom: 2px black solid !important;
            }
        }

        .special-cell {
            background-color: var(--ag-cell-special-background-color);
        }

        .white-cell {
            background-color: #ffffff;
        }

        .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover, .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:not(.ag-column-hover).ag-column-resizing,
        .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
        .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:not(.ag-column-hover).ag-column-resizing,
        .ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,
        .ag-header-group-cell:first-of-type.ag-column-resizing,
        .ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover,
        .ag-header-cell:not(.ag-column-hover):first-of-type.ag-column-resizing {
            &.special-cell {
                background-color: var(--ag-cell-special-background-color);
            }
            &.readonly-cell {
                background-color: #f3f5f6;
            }
        }
        .ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper {
            display: inline;
        }

        .hasAdjustment-cell {
            background-color: #1F9C29;
            font-weight: 700;
        }

        .hasAdjustment-cell {
            .check-icon::before {
                color: #ffffff !important;
            }
            div.selected
                .check-icon::before {
                    color: #ffffff !important;
                }
        }

        .hasError-cell {
            background-color: #BD362F !important;
            color: #FFFFFF;
        // color: white !important;
        }

        .error-cell {
            background-color: #BD362F !important;
            color: #FFFFFF;
        //  color: white !important;
        }

        .suspense-cell {
            background-color: #BF59EB !important;
            color: white !important;
        }

        .network-cell {
            background-color: #FFFF00 !important;
            color: black !important;
        }

        .custom-day {
            background-color: #FFC000;
            color: black !important;
        }

        .hasAdjustment-cell {
            myte-time-entry-grid-assignment-render .assignment-container {
                font-weight: 700;
            }

            myte-time-entry-grid-cell-render .grid-cell-container {
                a{
                    color: #FFFFFF;
                }
            }
        }

        /* time grid end*/

        /* location grid start */

        .ag-header-cell.error-cell {
            myte-day-header-renderer {
                .header-date {
                    color: white !important;
                }
            }
        }

        .ag-header-cell {
            myte-day-header-renderer {
                .header-date {
                    color: black;
                }
            }
        }

        /* location grid end */

        /* myte dropdown start */

        myte-dropdown .select-items {
            li {
                &:hover,
                &.selected {
                    color: #ffff;
                    background-color: #8364ca;
                }
            }
            .select-selected:focus {
                box-shadow: 0 0 6px #3194ff;
                border-color: #3194ff;
            }
        }

        /* set template - select charge code dropdown grid start */

        myte-time-entry-template-popup-grid-chargecode-editor .chargecode-list {
            .chargecode {
                &:hover {
                    color: #ffff;
                    background-color: #8364ca;
                    border: 1px solid black;
                }
                &.msacode {
                    background-color: #BD362F !important;
                // color: white !important;
                    &:hover {
                        border: 1px solid black;
                    }
                }
                &.isValid {
                    background-color: #BD362F !important;
                    //color: white !important;
                    &:hover {
                        border: 1px solid black;
                    }
                }
                &.chargecode:focus {
                    background-color: #8364ca;
                    color: #ffff;
                    border: 1px solid black;
                }
            }
        }

        myte-time-entry-template-popup-grid-chargecode-render .grid-cell-container {
            &.invalid {
                background-color: #BD362F;
                //color: white;
            }
        }

        .ag-theme-balham .ag-row-selected .ag-cell {
            .grid-cell-container {
                &.invalid {
                    background-color: #FDC6C6;
                    color: black !important;
                }
                &.msacode {
                    background-color: #FDC6C6 !important;
                    color: black !important;
                }
            }
        }

        .ag-theme-balham .ag-row {
            border-color: #000000 !important;
            border-width: 0 !important;
        }
        

        /* set template - select charge code dropdown grid end */
        /* myte dropdown end */

        /* alert box start */

        .alert-warning {
            color: #000000;
            background-color: #c28b15;
            border-color: #c28b15;
        }

        /* alert box end */

        /* date picker start */

        .input-group {
            .date-picker {
                &:focus {
                    box-shadow: 0 0 6px #3194ff;
                    border-color: #3194ff;
                }
            }
        }
        /* date picker end */

        /* expense view (forms) start */

        .form-inline .myte-charge-code-field{
                &.focus{
                    box-shadow: 0 0 6px #3194ff;
                    border-color: #3194ff;}
        }
        /* expense view (forms) start */

        // :host ::ng-deep .time-grid .ag-row.ag-row-first:hover {
        //     outline: 2px solid black !important;
        // }

        // :host ::ng-deep .time-grid .ag-row:hover {
        //     outline: 2px solid black !important;
        //     &.ag-row-last {
        //         outline: 2px solid black !important;
        //     }
        // }

        myte-tab-menu  {
            .nav-link:hover {
                    border-color: #000000;
            }
        }

        /* expense grid start */

        .ag-row-selected {
            myte-expense-grid-status-renderer p{
                color: #FFFFFF;
            }
        }

        /* expense grid end */

        .hide-label {
            .ag-header-cell-comp-wrapper {
                display: none !important;
            }
        }

        .ag-cell-value.linkEmployeeId {
            text-decoration: underline;
        }

        .ag-cell-value.linkEmployeeId:hover {
            cursor: pointer;
        }
        
        .reduce-space-header-overtimeRequested {
            padding: 0 !important;
            margin: 0 !important;
            white-space: nowrap !important;
        }

        .checkbox-center {
            .ag-cell-wrapper {
                height: 100%
            }
        }
        
        .summary-header-cell {
            border-right: 1px solid #bdc3c7;
        }
        .summary-location-header-cell {
            border-right: 1px solid #bdc3c7;
            border-top: 1px solid #8E8585;
            &.last-cell {
                border-right: 1px solid #8E8585;
            }
        }
        .summary-cell {
            border-right: 1px solid #bdc3c7;
            &.empty-cell {
                border-right: 0px !important;
            }
            &.last-cell {
                border-right: 1px solid #8E8585;
            }
        }
        .summary-row {
            border-bottom-width: 1px;
            border-top-width: 0px;
        }
    }
}