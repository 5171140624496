@font-face
  font-family: 'Graphik'
  src: local("Graphik Regular")
  src: url("./assets/fonts/graphik/Graphik-Regular-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Regular-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Regular-Web.woff") format("woff")
  font-weight: 400
  font-style: normal
  font-stretch: normal

@font-face
  font-family: 'Graphik Regular Italic'
  src: local("Graphik Regular Italic")
  src: url("./assets/fonts/graphik/Graphik-RegularItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-RegularItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-RegularItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-RegularItalic-Web.woff") format("woff")
  font-weight: 400
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Super Italic'
  src: local("Graphik Super Italic")
  src: url("./assets/fonts/graphik/Graphik-SuperItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-SuperItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-SuperItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-SuperItalic-Web.woff") format("woff")
  font-weight: 900
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Super'
  src: local("Graphik Super")
  src: url("./assets/fonts/graphik/Graphik-Super-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Super-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Super-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Super-Web.woff") format("woff")
  font-weight: 900
  font-style: normal
  font-stretch: normal

@font-face
  font-family: 'Graphik Black Italic'
  src: local("Graphik Black Italic")
  src: url("./assets/fonts/graphik/Graphik-BlackItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-BlackItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-BlackItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-BlackItalic-Web.woff") format("woff")
  font-weight: 800
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Black'
  src: local("Graphik Black")
  src: url("./assets/fonts/graphik/Graphik-Black-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Black-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Black-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Black-Web.woff") format("woff")
  font-weight: 800
  font-style: normal
  font-stretch: normal

@font-face
  font-family: 'Graphik Bold Italic'
  src: local("Graphik Bold Italic")
  src: url("./assets/fonts/graphik/Graphik-BoldItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-BoldItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-BoldItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-BoldItalic-Web.woff") format("woff")
  font-weight: 700
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Bold'
  src: local("Graphik Bold")
  src: url("./assets/fonts/graphik/Graphik-Bold-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Bold-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Bold-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Bold-Web.woff") format("woff")
  font-weight: 700
  font-style: normal
  font-stretch: normal

@font-face
  font-family: 'Graphik Semibold Italic'
  src: local("Graphik Semibold Italic")
  src: url("./assets/fonts/graphik/Graphik-SemiboldItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-SemiboldItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-SemiboldItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-SemiboldItalic-Web.woff") format("woff")
  font-weight: 600
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Semibold'
  src: local("Graphik Semibold")
  src: url("./assets/fonts/graphik/Graphik-Semibold-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Semibold-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Semibold-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Semibold-Web.woff") format("woff")
  font-weight: 600
  font-style: normal
  font-stretch: normal

@font-face
  font-family: 'Graphik Medium Italic'
  src: local("Graphik Medium Italic")
  src: url("./assets/fonts/graphik/Graphik-MediumItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-MediumItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-MediumItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-MediumItalic-Web.woff") format("woff")
  font-weight: 500
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Medium'
  src: local("Graphik Medium")
  src: url("./assets/fonts/graphik/Graphik-Medium-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Medium-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Medium-Web.woff") format("woff")
  font-weight: 500
  font-style: normal
  font-stretch: normal

@font-face
  font-family: 'Graphik Light Italic'
  src: local("Graphik Light Italic")
  src: url("./assets/fonts/graphik/Graphik-LightItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-LightItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-LightItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-LightItalic-Web.woff") format("woff")
  font-weight: 300
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Light'
  src: local("Graphik Light")
  src: url("./assets/fonts/graphik/Graphik-Light-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Light-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Light-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Light-Web.woff") format("woff")
  font-weight: 300
  font-style: normal
  font-stretch: normal

@font-face
  font-family: 'Graphik Extralight Italic'
  src: local("Graphik Extralight Italic")
  src: url("./assets/fonts/graphik/Graphik-ExtralightItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-ExtralightItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-ExtralightItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-ExtralightItalic-Web.woff") format("woff")
  font-weight: 200
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Extralight'
  src: local("Graphik Extralight")
  src: url("./assets/fonts/graphik/Graphik-Extralight-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Extralight-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Extralight-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Extralight-Web.woff") format("woff")
  font-weight: 200
  font-style: normal
  font-stretch: normal

@font-face
  font-family: 'Graphik Thin Italic'
  src: local("Graphik Thin Italic")
  src: url("./assets/fonts/graphik/Graphik-ThinItalic-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-ThinItalic-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-ThinItalic-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-ThinItalic-Web.woff") format("woff")
  font-weight: 100
  font-style: italic
  font-stretch: normal

@font-face
  font-family: 'Graphik Thin'
  src: local("Graphik Thin")
  src: url("./assets/fonts/graphik/Graphik-Thin-Web.woff2") format("woff2")
  src: url("./assets/fonts/graphik/Graphik-Thin-Web.eot")
  src: url("./assets/fonts/graphik/Graphik-Thin-Web.eot?#iefix") format("embedded-opentype")
  src: url("./assets/fonts/graphik/Graphik-Thin-Web.woff") format("woff")
  font-weight: 100
  font-style: normal
  font-stretch: normal